import React from "react";
import ClipboardCopy from "./ClipboardCopy";

export default function RoomInfo({ room }) {
  return (
    <div className="w-full mb-5">
      <h2 className="font-bold text-xl">Invite to Room:</h2>

      {/*Todo: adapt to app url!*/}
      <ClipboardCopy text={`https://marqmate.mqt.at/join/${room}`} />
    </div>
  );
}
