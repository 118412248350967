function ClipboardCopy({text}) {

  return (
    <div className="flex">
        <input className="border border-front-secondary px-3 py-1 w-full bg-front-input" type="text" value={text} readOnly />

        <button className="bg-front-primary hover:bg-front-primaryHover transition px-3 text-front-text2"
                onClick={() => navigator.clipboard.writeText(text)}>
            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinejoin="round" strokeWidth="2"
                      d="M9 8v3a1 1 0 0 1-1 1H5m11 4h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v1m4 3v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L7.7 8.35A1 1 0 0 1 8.46 8H13a1 1 0 0 1 1 1Z"/>
            </svg>
        </button>
    </div>
  );
}

export default ClipboardCopy;
