import React, { useState, useEffect } from 'react';

export default function FullScreenVideo({ animationType = "start", player1 = "", player2 = "", winner, onFadeOutEnd = () => {}}) {
    const [isFading, setIsFading] = useState(false);
    const [videoSrc, setVideoSrc] = useState("");
    const [showVideo, setShowVideo] = useState(false);

    const handleVideoEnd = () => {
        setIsFading(true);

        setTimeout(() => {
            onFadeOutEnd();
            setShowVideo(false);

            setIsFading(false);
        }, 1000);
    };

    useEffect(() => {
        setIsFading(false);

        //find a fix for video race condition
        setTimeout(() => {
            setShowVideo(true);
        }, 100);

        switch (animationType) {
            case "start":
                setVideoSrc("video/start-video.mp4");
                break;
            case "white-wins":
                setVideoSrc("video/white-wins.mp4");
                break;
            case "black-wins":
                setVideoSrc("video/black-wins.mp4");
                break;
            case "draw":
                //todo: add draw video
                //setVideoSrc("video/draw.mp4");
                setVideoSrc("video/white-wins.mp4");
                break;
            default:
                setVideoSrc("video/start-video.mp4"); // Fallback case
                break;
        }
    }, [animationType]);

    const winningPlayer = winner ? (player1.side === winner ? player1 : player2) : null;

    return (
        <div className={`absolute w-full h-full z-50 ${isFading ? 'fade-out' : ''}`}
             style={{transition: 'opacity 1s ease-in-out', opacity: isFading ? 0 : 1}}>
            <div className="relative w-full h-full">
                {showVideo ?
                    <video
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            border: 'none',
                        }}
                        autoPlay
                        muted
                        onEnded={handleVideoEnd}
                    >
                        <source src={videoSrc} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video> : <></>
                }

                {winningPlayer ? (
                        <h2 className="absolute w-full text-5xl text-front-text2 bottom-5 font-bold text-center flex justify-center items-center gap-2">
                            {winningPlayer.username} wins!
                        </h2>
                    ) : (
                        <h2 className="absolute w-full bottom-5 font-bold text-front-text2 text-center flex justify-center items-center gap-2">
                            <span className="text-4xl font-bold">{player1.username}</span>
                            <span className="text-2xl text-front-primary">vs.</span>
                            <span className="text-4xl font-bold">{player2.username}</span>
                        </h2>
                    )}
            </div>
        </div>

    );
}
