import { useState, useMemo, useCallback, useEffect } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import socket from "./socket";
import RoomInfo from "./components/RoomInfo";
import FullScreenVideo from "./components/FullScreenVideo";

function Game({ players, room, orientation, cleanup }) {
  const chess = useMemo(() => new Chess(), []);
  const [fen, setFen] = useState(chess.fen());
  const [over, setOver] = useState(false);
  const [message, setMessage] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [moveFrom, setMoveFrom] = useState("");
  const [optionSquares, setOptionSquares] = useState({});
  const [showAnimation, setShowAnimation] = useState(false);
  const [winner, setWinner] = useState(null);

  const sendGamoverEmit = useCallback(
    (data) => {
      /*if (orientation === "black") return;*/
      socket.emit("gameOver", { ...data, sentBy: orientation });
    },
    [orientation]
  );

  const makeAMove = useCallback(
    (move) => {
      try {
        const result = chess.move(move);
        setFen(chess.fen());
        setMessage("");

        if (chess.isGameOver()) {
          if (chess.isCheckmate()) {
            const winner = chess.turn() === "w" ? "black" : "white";
            setWinner(winner ?? "white");
            setShowAnimation(true);
            sendGamoverEmit({
              room,
              draw: false,
              winner,
            });

            setOver(true);
          } else if (chess.isDraw()) {
            sendGamoverEmit({ room, draw: true });
            setOver("Draw");
          } else {
            sendGamoverEmit({ room, draw: false, winner: null });
            setOver("Game over");
          }
        }

        return result;
      } catch (e) {
        return null;
      }
    },
    [chess, sendGamoverEmit, room]
  );
  const getPlayerMap = () => {
    const playerMap = {};
    players.forEach((player) => {
      playerMap[player.side] = player.username;
    });

    return playerMap;
  };
  const getCurrentPlayer = () => {
    const currentTurn = chess.turn(); // "w" or "b"
    const [WHITE] = ["w", "b"];
    const playerMap = getPlayerMap();
    return currentTurn === WHITE ? playerMap["white"] : playerMap["black"];
  };

  // onDrop function
  function onDrop(sourceSquare, targetSquare) {
    // orientation is either 'white' or 'black', chess.turn() returns 'w' or 'b'
    if (chess.turn() !== orientation[0]) {
      setMessage("It's not your turn!");
      return false; // prohibit player from moving piece of other player
    }

    if (players.length < 2) return false; // disallow a move if the opponent has not joined

    const moveData = {
      from: sourceSquare,
      to: targetSquare,
      color: chess.turn(),
      promotion: "q", // promote to queen where possible
    };

    const move = makeAMove(moveData);

    // illegal move
    if (move === null) return false;

    socket.emit("move", {
      move,
      room,
    });

    setMoveFrom("");
    setOptionSquares({});

    return true;
  }

  // Function to get and display move options
  function getMoveOptions(square) {
    if (chess.turn() !== orientation[0]) {
      return false; // prohibit player from moving piece of other player
    }
    const moves = chess.moves({ square, verbose: true });
    if (moves.length === 0) {
      setOptionSquares({});
      return false;
    }
    const newSquares = {};
    moves.forEach((move) => {
      newSquares[move.to] = {
        background:
          chess.get(move.to) &&
          chess.get(move.to).color !== chess.get(square).color
            ? "radial-gradient(circle, rgba(0,0,0,.1) 85%, transparent 85%)"
            : "radial-gradient(circle, rgba(0,0,0,.1) 25%, transparent 25%)",
        borderRadius: "50%",
      };
    });
    newSquares[square] = { background: "#fdcd59" };
    setOptionSquares(newSquares);
    return true;
  }

  // Handle click-to-move functionality
  function onSquareClick(square) {
    if (chess.turn() !== orientation[0]) {
      return false; // prohibit player from moving piece of other player
    }
    // Clear previous right-clicked or option squares
    setMessage("");

    // Selecting the piece to move from
    if (!moveFrom) {
      const hasMoveOptions = getMoveOptions(square);
      if (hasMoveOptions) setMoveFrom(square);
      return;
    }

    // Move to the target square
    const move = makeAMove({
      from: moveFrom,
      to: square,
      promotion: "q", // Promote to queen by default
    });

    if (move === null) {
      // Invalid move, allow selecting a new piece
      const hasMoveOptions = getMoveOptions(square);
      setMoveFrom(hasMoveOptions ? square : "");
      return;
    }

    // Emit move to the server
    socket.emit("move", { move, room });

    // Reset moveFrom and optionSquares after a valid move
    setMoveFrom("");
    setOptionSquares({});
  }

  function giveUp() {
    const winner = orientation === "black" ? "white" : "black";
    setWinner(winner);
    setOver(true);
    setShowAnimation(true);

    sendGamoverEmit({
      room,
      draw: false,
      winner,
    });
  }

  useEffect(() => {
    socket.on("move", (move) => {
      makeAMove(move);
    });
  }, [makeAMove]);

  useEffect(() => {
    socket.on("playerDisconnected", (player) => {
      setOver(true);
    });

    socket.on("gameHasEnded", (winner) => {
      setOver(true);
      setWinner(winner ?? "white");
      setShowAnimation(true);
    });
  }, []);

  useEffect(() => {
    socket.on("closeRoom", ({ roomId }) => {
      if (roomId === room) {
        cleanup();
      }
    });
  }, [room, cleanup]);

  useEffect(() => {
    socket.on("gameStart", ({ startTime }) => {
      setStartTime(startTime);
      setShowAnimation(true);
    });

    return () => {
      socket.off("gameStart");
    };
  }, []);

  useEffect(() => {
    if (startTime) {
      const interval = setInterval(() => {
        setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [startTime]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const onVideoFadeOutEnd = () => {
    setShowAnimation(false);
  };

  return (
    <div className="flex justify-center items-center relative">
      {showAnimation && winner && (
        <FullScreenVideo
          animationType={over ? `${winner}-wins` : "start"}
          player1={players[0]}
          player2={players[1]}
          winner={winner}
          onFadeOutEnd={onVideoFadeOutEnd}
        />
      )}
      {showAnimation && !winner && (
        <FullScreenVideo
          animationType={over ? `${winner}-wins` : "start"}
          player1={players[0]}
          player2={players[1]}
          winner={winner}
          onFadeOutEnd={onVideoFadeOutEnd}
        />
      )}

      <div>
        <div
          className="board max-w-[90vh] w-[90vw] md:max-w-[80vh] md:w-[80vw] lg:max-w-[70vh] lg:w-[70vw] relative"
          style={{
            margin: "3rem auto 2rem auto",
          }}
        >
          {players.length < 2 && (
            <div className="absolute top-0 bottom-0 right-0 left-0 z-40 flex items-center justify-center px-5">
              <div className="absolute top-0 bottom-0 right-0 left-0 bg-gray-900 bg-opacity-70 backdrop-blur-sm z-10"></div>

              <div className="relative z-20 w-full">
                <RoomInfo room={room} />
              </div>
            </div>
          )}
          <Chessboard
            position={fen}
            onSquareClick={onSquareClick}
            onPieceDrop={onDrop}
            boardOrientation={orientation}
            customBoardStyle={{
              borderRadius: "4px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
            }}
            customDarkSquareStyle={{
              backgroundColor: "#FFE5A7",
            }}
            customLightSquareStyle={{
              backgroundColor: "#fff",
            }}
            customSquareStyles={optionSquares}
          />
        </div>

        {players.length >= 2 && (
          <div>
            <div className="mx-auto">
              <h2
                id="text"
                className="mb-5 flex justify-center items-center gap-2"
              >
                <span className="text-4xl font-bold">
                  {players[0].username}
                </span>
                <span className="text-2xl text-front-primary">vs.</span>
                <span className="text-4xl font-bold">
                  {players[1].username}
                </span>
              </h2>
            </div>

            <div className="lg:absolute bottom-10 left-5 border border-4 border-dashed border-front-primary bg-amber-50 p-5 text-front-text2 mb-8 lg:mb-0">
              {startTime && (
                <p>
                  <span className="font-bold text-lg">Game Time:</span>
                  {formatTime(elapsedTime)}
                </p>
              )}

              <p>
                <span className="font-bold text-lg">Current Turn:</span>
                {getCurrentPlayer()}
              </p>

              {message && <p className="">{message}</p>}
              {over ? (
                <div className="flex justify-center pb-2 mt-3">
                  <button
                    className="inline-block no-underline uppercase font-bold px-4 py-2 border border-black shadow-[4px_4px_0px_0px_black] transition-all duration-300 ease-in-out hover:shadow-[-1px_-1px_3px_0px_white] hover:mt-[2px] hover:ml-[2px] hover:mb-[-2px] hover:mr-[-2px]"
                    onClick={cleanup}
                  >
                    New Game
                  </button>
                </div>
              ) : (
                <div className="flex justify-center pb-2 mt-3">
                  <button
                    className="inline-block no-underline uppercase font-bold px-4 py-2 border border-black shadow-[4px_4px_0px_0px_black] transition-all duration-300 ease-in-out hover:shadow-[-1px_-1px_3px_0px_white] hover:mt-[2px] hover:ml-[2px] hover:mb-[-2px] hover:mr-[-2px]"
                    onClick={giveUp}
                  >
                    Resign
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div class="absolute bottom-5 right-5">
        <span className="text-sm font-bold">Copyright by Kerstin & Nidhil</span>
      </div>
    </div>
  );
}

export default Game;
