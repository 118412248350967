import { useParams, useNavigate } from "react-router-dom";
import socket from "../socket";
import { useEffect, useState } from "react";
import CustomDialog from "./CustomDialog";

import { useLocation } from "react-router-dom";

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}
const JoinGame = ({
  setRoom,
  setPlayers,
  setOrientation,
  usernameSubmitted,
  cleanup,
  setUsername,
  setUsernameSubmitted,
  baseUsername
}) => {
  const { room } = useParams();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const [roomError, setRoomError] = useState("");

  useEffect(() => {
    const userId = queryParams.get("userId");
    const username = queryParams.get("name");
    if (username) {
      setUsername(username);
      setUsernameSubmitted(true);
    }
    if (!room || !usernameSubmitted) return; // if no room is provided, exit

    socket.emit("joinRoom", { roomId: room, userId, username: username ?? baseUsername }, (r) => {
      console.log("response:", r);
      // r is the response from the server
      if (r.error) return setRoomError(r.message); // if an error is returned in the response set roomError to the error message and exit

      setRoom(r?.roomId); // set room to the room ID
      setPlayers(r?.players); // set players array to the array of players in the room
      setOrientation(r?.side ?? "black"); // set orientation as black
      navigate("/"); // redirect to the game page
    });
  }, [
    room,
    setRoom,
    setPlayers,
    setOrientation,
    navigate,
    usernameSubmitted,
    setUsername,
    setUsernameSubmitted,
    queryParams,
  ]);

  return (
    <div>
      <h1>Joining room {room}</h1>
      <CustomDialog
        open={Boolean(roomError)}
        title={"Oops!"}
        contentText={roomError}
        handleContinue={() => {
          socket.emit("closeRoom", { roomId: room });
          cleanup();
          navigate("/");
        }}
      />
    </div>
  );
};

export default JoinGame;
